@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');



.ASDSection-header {
    width: auto;
    padding: 20px;
    text-align: center;
    margin: 2rem auto;
}

.ASDSection-header h1 {
    font-family: 'Montserrat', sans-serif;
    text-align: left;
}

.ASDSection-header p {
  font-family: 'Montserrat', sans-serif;
  text-align: justify;
}



