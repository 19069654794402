@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');



.alcohol-section-header {
    width: auto;
    padding: 20px;
    margin: 2rem auto;
}

.alcohol-section-header ul {
  margin-left: 1rem;
}

.alcohol-section-header ul li {
  list-style-type: disc;
  padding: .2rem 0
}

.alcohol-section-header h1 {
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    font-weight: normal;
}

.alcohol-section-header h2 {
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  font-weight: normal;
  padding: .5rem 0;
}

.alcohol-section-header p {
  font-family: 'Montserrat', sans-serif;
  text-align: justify;
}

.contact2-header {
  text-align: center;
}

