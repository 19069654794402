.topbar {
    width: 100%;
    padding: .2rem;
    background-color: #D2A13A;
    position: relative;

}

.topbar .topbar-content {
    display: flex;
    color: white;
    justify-content: space-around;
}

.topbar .topbar-content p {
    padding: 0 1rem;
    font-size: 12px;
    letter-spacing: 1px;
}

.topbar .bar-box1 {
    width: 25rem;
    border-right: 1px solid rgba(229, 175, 88, 0.438);
}

.topbar .bar-box2 {
    width: 20rem;
    border-right: 1px solid rgba(229, 175, 88, 0.438);
}

.topbar .bar-box3 a {
    color: white;
}

.topbar .bar-box3 a:hover {
    color: var(--primary-color);
}

.topbar span {
    color: white;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 2px;
}



@media screen and (max-width: 940px) {
    .bar-box1, .bar-box3 {
        display: none;
    }
}