
.testimonials-section {
    margin-top: 3rem;
    padding: 10px;
    padding:  3rem 0;
}

.testimonial-wrap {
    display: flex;
    flex-wrap: wrap ;
    justify-content: center;
    gap: 1rem;
  }
  
  .testimonial-left{
      width: 50%;
      margin: auto;
  }
  
.testimonial-right {
    width: 500px;
}

  .testimonial-right img {
    border-radius: 15px;
    width: 100%;
}

.testimonials-section p {
    text-align: justify;
    line-height: 20px;
}

.testimonials-section h1 {
    margin: 1rem 0;
}


@media screen and (max-width:1200px) {
    .testimonial-left {
        width: 100%;
        padding: 1rem;
    }
}

@media screen and (max-width:420px) {


    .testimonial-right img {
        width: 350px;
        height: 250px;
        margin: 10px;
    }

}






