@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.treatment {
    width: 100%;
    height: 80vh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: #05050560;
  }
  
  .treatment:before {
    content: '';
    position: absolute;
    background: url('../../assets//menu-bg-treatment.webp') no-repeat center top/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.treatment .treatment-content {
    display: block;
    position: absolute;
    bottom: 20%;
    left: 10%;
    padding: 1rem;
}

.treatment h1 {
    color: white;
    font-size: 3rem;
}


/* MEDIA QUERIES - HERO */

@media screen and (max-width:940px) {

.treatment h1 {
    font-size: 3rem;
    text-align: center;
}

.treatment .treatment-content {
    left: 0%;
    width: 100%;
}
  


}