@import url('https://fonts.googleapis.com/css2?family=Caveat+Brush&display=swap');

.c1 {
    width: 100%;
    height: auto;
    position: relative;
  }
  
  .c1:before {
    content: '';
    position: absolute;
    background: url('../../assets/bg1.png') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.1;
    
  }


.c1-flex {
    display: flex;
    flex-wrap: wrap ;
    justify-content: center;
    padding: 2rem 0;
  }
  
  .c1-left {
      width: 50%;
      margin: 1rem;
      margin: auto;
      padding: 1rem;
  }
  
  .c1-right {
      width: 500px;
  }
  
  .c1-right img {
      width: 100%;
      height: auto;
      padding: 1rem;
      border-radius: 2rem;
  }

  .c1-left h2 {
    font-size: 1rem;
    letter-spacing: 3px;
  }

  .c1-left p {
    text-align: justify;
  }

  .c1-flex h1 {
    font-family: 'Caveat Brush', cursive;
    font-size: 1.8rem;
    letter-spacing: 1px;
    color: var(--secondary-color);
  }

  @media  screen and (max-width:1200px){
    .c1-left {
      width: 100%;
      text-align: center;
    }
  }