.core {
    width: 100%;
    height: auto;
    position: relative;
  }
  
  .core:before {
    content: '';
    position: absolute;
    background: url('../../assets/bg1.png') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.1;
    
  }


.team-section {
    width: 100%;
    position: relative;
}

.team-section .content {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 1rem;
}

.team-section .content p {
    margin: 1rem 0;
}

.team-section p {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    padding: 0 1rem;
}


.team-section h1 {
    padding: 2rem 0 0 0;
    margin: 2rem 0;
    font-size: 1.5rem;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    color: var(--primary-color);
}

.flex-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin-top: 1rem;
    gap: 1rem;
}

.thumbnail {
    width: 250px;
}

.thumbnail img {
    width: 100%;
    margin: 5px;
    border-radius: 1rem;
}

.thumbnail p {
    width: 100%;
    text-align: center;
}

.mission-section button {
    margin: auto;
}

@media screen and (max-width: 940px) {
    .team-section .content {
        width: 100%;
    }

    .team-section h1 {
        font-size: 25px;
    }

    .team-section p {
        font-size: 15px;
    }

}






