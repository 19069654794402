@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.hero {
    width: 100%;
    height: 80vh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: #000000;
  }

  .hero-container .content {
    padding: 1rem;
  }

  .hero .hero-container {
    height: fit-content;
    bottom: 10%;
    left: 8%;
    position: absolute;
    align-items: center;
    text-align: center;
}

.hero-container h1 span {
    color: #f7941d;
}
.slideshow {
    list-style-type: none;
  }
  
  /** SLIDESHOW **/
  .slideshow,
  .slideshow:after { 
      top: -16px; /*Not sure why I needed this fix*/
      width: 100%;
      height: 100%;
      left: 0px;
      z-index: 0; 
  }
  
  .slideshow li span { 
          position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      color: transparent;
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 0;
      z-index: 0;
      animation: imageAnimation 30s linear infinite 0s; 
  }
  
  
  .slideshow li:nth-child(1) span { 
      background-image: linear-gradient(to top, #0000006c, #00000062), url("../../assets/hero1.webp"); 
  }
  .slideshow li:nth-child(2) span { 
    background-image: linear-gradient(to top, #0000006c, #00000062), url("../../assets/hero2.webp") ; 
    animation-delay: 6s; 
  }
  .slideshow li:nth-child(3) span { 
    background-image: linear-gradient(to top, #0000006c, #00000062), url("../../assets/hero3.webp"); 
    animation-delay: 12s; 
  }
  .slideshow li:nth-child(4) span { 
    background-image: linear-gradient(to top, #0000006c, #00000062), url("../../assets/hero4.webp"); 
    animation-delay: 18s; 
  }
  .slideshow li:nth-child(5) span { 
    background-image: linear-gradient(to top, #0000006c, #00000062), url("../../assets/hero5.webp"); 
    animation-delay: 24s; 
  }
  
  
  
  @keyframes imageAnimation { 
      0% { opacity: 0; animation-timing-function: ease-in; }
      8% { opacity: 1; animation-timing-function: ease-out; }
      17% { opacity: 1 }
      25% { opacity: 0 }
      100% { opacity: 0 }
  }
  
  
  @keyframes titleAnimation { 
      0% { opacity: 0 }
      8% { opacity: 1 }
      17% { opacity: 1 }
      19% { opacity: 0 }
      100% { opacity: 0 }
  }
  
  
  .no-cssanimations .cb-slideshow li span {
      opacity: 1;
  }


.hero h1 {
    font-size: 6rem;
    text-align: center;
    color: white;
    font-family: 'Kanit', sans-serif;        
    line-height: 5rem;
    letter-spacing: 5px;
}

.hero h2 {
    font-size: 1.5rem;
    text-align: center;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
}

.hero-container p {
    font-size: 18px;
    text-align: center;
    color: orange;
}

.hero .hero-btn button {
    cursor: pointer;
    margin: auto;
    transition: 0.3s;
}

.hero .hero-btn button:hover {
    background-color: white;
}


.hero-container span {
    color: white;
}


/* make keyframes that tell the start state and the end state of our object */

@-webkit-keyframes fadeIn { 
    0%   { opacity: 0; }
      100% { opacity: 1; }
    }
    @-moz-keyframes fadeIn { 
    0%   { opacity: 0; }
      100% { opacity: 1; }
    }
    @keyframes fadeIn { 
     0%   { opacity: 0; }
      100% { opacity: 1; } 
    }
    
    .fade-in {
        opacity:0;  
        /* make things invisible upon start */
        
        -webkit-animation:fadeIn ease-in 1;  
        -moz-animation:fadeIn ease-in 1;
        animation:fadeIn ease-in 1;
    
        -webkit-animation-fill-mode:forwards;  
        -moz-animation-fill-mode:forwards;
        animation-fill-mode:forwards;
    
        -webkit-animation-duration:1s;
        -moz-animation-duration:1s;
        animation-duration:1s;
    }
    
    .fade-in.one {
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    animation-delay: 0.5s;
    }
    
    .fade-in.two {
    -webkit-animation-delay: 1.2s;
    -moz-animation-delay: 1.2s;
    animation-delay: 1.2s; 
    }
    
    .fade-in.three {
    -webkit-animation-delay: 2s;
    -moz-animation-delay: 2s;
    animation-delay: 2s;
    }


    .fade-in.four {
        -webkit-animation-delay: 3s;
        -moz-animation-delay: 3s;
        animation-delay: 3s;
    }
    

/* MEDIA QUERIES */

 @media screen and (max-width:940px) {

    .hero h1 {
        font-size: 5rem;
        line-height: 4.5rem;
    }

    .hero h2 {
        font-size: 20px;
    }

    .hero p {
        font-size: 15px;
    }

    .hero .container {
        left: 0;
    }
    
    .hero .hero-container {
        width: 100%;
    }

}


@media screen and (max-width:500px) {

 .hero-container img {
    width: 340px;
    height: 120px;
 }

 .hero h1 {
    font-size: 4rem;
    line-height: 3.5rem;
}


} 
