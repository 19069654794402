@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.navbar {
    width: 100%;
    height: auto;
    position: absolute;
    z-index: 10;
    background: #235151;
    padding: .5rem 0;
    border-bottom: 1px solid rgba(255, 166, 0, 0.342);
}

.navbar .navbar-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.navbar-logo {
  width: 120px;
  z-index: 2;
}

.navbar-logo img {
  width: 100%;
}

.navbar-logo h1 {
  padding: 1rem;
  color: white;
}

.navbar .icon {
  font-size: 1.4rem;
  color: rgb(255, 255, 255);
}

.navbar .logo .icon {
    font-size: 3rem;
    margin-right: 1rem;
    color: var(--primary-color);
}

.hamburger {
    display: none;
    padding: 1rem;
}

.navbar .nav-menu {
    height: 100%;
    align-items: center;
    display: flex;
}

.navbar li {
    font-size: 13px;
    width: max-content;
    padding: .5rem 1rem;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
}



  .menu-item .sub__menus li a {
    color: #ffffff;
    font-size: 13px;
    padding: -1rem ;
    font-weight: 300;
  }

  .menu-item .sub__menus li a:hover {
    font-weight: 500;
  }

/* -----------------------------------------------------> SUB MENU */
.sub__menus {
    position: absolute;
    display: none;
    background: #235151;
    border: none;
    border-radius: 5px;
    width: max-content;
    padding: 2px 0 0 0;
    margin-top: 6px;
    z-index: 1000;
    width: 250px;

  }

  /* -----------------------------------------------------> DROPDOWN ARROW */
  .sub__menus__arrows svg, .sub__menus__arrows__full svg {
    top: 10px;
    font-size: 12px;
  }

/* Hover Underline Animation */

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #0087ca;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #D2A13A;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

  
@media screen and (max-width: 1200px) {
    .navbar .hamburger {
        display: block;
        z-index: 15;
    }

    .navbar .nav-menu {
        position: absolute;
        flex-direction: column;
        width: 100%;
        height: 80vh;
        top: 0;
        left:-100%;
        justify-content: center;
        background: var(--primary-color);
        transition: 0.5s ease-in;
    }

    .navbar .active {
        left: 0;
    }

    .navbar li {
        font-size: 1rem;
    }

    .navbar button {
        font-size: 1.8rem;
    }

    .menu-item .sub__menus li a {
        font-size: 14px;
      }

      li .submenu-header {
        font-size: 14px;
      }

      #submenu-item li {
        width: 100%;
        text-align: center;
        padding: .5rem 0;

      }


      .navbar .navbar-container {
        justify-content: space-between;
      }

      .sub__menus {
        background: #1b4141;
        width: 100%;
        left: 0;
        border-radius: 0;

      }


      .menu-item .sub__menus li {
        border-bottom: 1px dashed rgba(255, 255, 255, 0.212);
      }

      .menu-item .sub__menus li a {
        color: rgb(255, 255, 255);
      }
    
  
}





