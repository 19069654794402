@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.footer {
    width: 100%;
    height: auto;
    position: relative;
    background: var(--primary-color);
    padding: 3rem 0;
}

.footer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.col {
    width: 300px;
    text-align: center;
}

.footer .top {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}

.footer .top .logo-footer {
    display: flex;
    align-items: center;
}

.footer .top .footer-icon {
    font-size: 3rem;
    margin-right: 1rem;
    color: white;
    cursor: pointer;
}

.footer h1 {
    font-size: 2rem;
    color: white;
}

.footer h2 {
    font-size: 2rem;
}

.footer h3 {
    color: rgb(255, 255, 255);
    font-size: 1.2rem;
    font-weight: normal;
}

.footer .col-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding: 2rem 0;
    width: 100%;
}

.footer h3 {
    margin: 1rem 0;
    text-transform: uppercase;
}


.footer p {
    font-family: 'Montserrat', sans-serif;
    margin: .5rem 0;
    color: white;
    font-weight: 500;
    text-align: center;
}


.footer form {
    width: 100%;
    margin-bottom: .6rem;
    position: relative;
}

.footer form input {
    padding: 1rem;
    width: 100%;
    outline: none;
    font-size: 1rem;
    border: 1px solid #aaa;
    border-radius: 4px;
    color: #333;
}

.footer .col .social-icon {
    margin: 1rem 1rem 1rem 0;
    padding: .6rem;
    background-color: var(--primary-color);
    border-radius: 4px;
    color: #f8f8f8;
    font-size: 3rem;
}


.copyright p {
    color: white;
    text-transform: uppercase;
}

.certification {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
}

.certificationLeft {
    width: 100%;
    text-align: center;
    margin: auto;
}

.certificationRight {
    width: 100%;
    margin: auto;
}

.certificationRight p {
    text-align: center;
}



.certificationLeft p {
    text-align: left;
    color: white;
}

.certificationLeftImage {
    width: 200px;
    margin: auto 0;
}

.certificationLeftImage img{
    width: 100%;
}

.certificationRightImage {
    width: 300px;
    margin: auto;
}

.certificationRightImage img{
    width: 100%;
}

.certification a {
    text-align: center;
    color: rgb(235, 183, 10);
}


.certification a:hover {
    text-align: center;
    color: rgb(10, 208, 235);
}


@media screen and (max-width: 940px) {


    .certificationLeftImage {
        width: 200px;
        margin: auto;
    }

    .certification p {
        text-align: center;
    }
    

    .certification {
        flex-wrap: wrap;
    }

    .certificationRight {
        width: 100%;
    }

    .certificationLeft {
        width: 100%;
    }
    
    
    

}



@media screen and (max-width: 940px) {
    .footer {
        height: 100%;
    }

}


